import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
  IconButton,
  Hidden,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FiSun } from "react-icons/fi";
import SettingsContext from "src/context/SettingsContext";
import { FaRegMoon } from "react-icons/fa";
import CookieConsent from "react-cookie-consent";
const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",
    backgroundColor: theme.palette.background.dark,
    // background: "#fff",
    padding: "0 60px",
    color: theme.palette.text.primary,
    // color: "#000",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  left: {
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainbox: {
    // boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.3)",
    backgroundColor: theme.palette.background.login,
    backdropFilter: "blur(42px)",
    color: theme.palette.text.primary,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  logoImage: {
    position: "absolute",
    zIndex: "1",
    top: "20px",
    maxWidth: "225px",
    cursor: "pointer",
    "@media(max-width:1279px)": {
      display: "none",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = React.useContext(SettingsContext);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [themeChange, seThemeChange] = useState("");
  useEffect(() => {
    const activeTheme = themeSeeting?.themekey?.theme;
    seThemeChange(activeTheme);
  }, []);
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={6} className={classes.left}>
          <Hidden mdDown>
            <Container>
              {themeSeeting.settings.theme === "DARK" ? (
                <img
                  src="images/VD1.png"
                  alt=""
                  className={classes.logoImage}
                  // className="Unoique"
                  onClick={() => history.push("/")}
                />
              ) : (
                <>
                  <img
                    src="images/VD.png"
                    alt=""
                    className={classes.logoImage}
                    // className="Unoique"
                    onClick={() => history.push("/")}
                  />
                </>
              )}
            </Container>
          </Hidden>
          <Box
            className={classes.mainbox}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box className="signupmain">
              <Box align="center">
                {window.location?.pathname === "/" ||
                  window.location?.pathname === "/signup" ? (
                  <img
                    src="images/signUpImg.png"
                    alt=""
                  // className="Unoique"
                  // onClick={() => history.push("/")}
                  />
                ) : window.location?.pathname === "/verify-otp" ||
                  window.location?.pathname === "/reset-password" ||
                  window.location?.pathname === "/verify-email-otp" ? (
                  <img
                    src="images/verifyImg.png"
                    alt=""
                    style={{ marginBottom: "30px" }}
                  // className="Unoique"
                  // onClick={() => history.push("/")}
                  />
                ) : (
                  ""
                )}

                {window.location?.pathname === "/forget-password" && (
                  <>
                    <img
                      src="images/forgot123.png"
                      alt=""
                      style={{ marginBottom: "30px" }}
                    />
                  </>
                )}
              </Box>

              {window.location?.pathname === "/login" ||
                window.location?.pathname === "/signup" ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    style={{ marginTop: "-30px", paddingLeft: "80px" }}
                  >
                    <Typography variant="h2">Welcome to</Typography>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "45px",

                        // background:
                        //   "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
                        // WebkitBackgroundClip: "text",
                        // WebkitTextFillColor: "transparent",
                        // backgroundClip: "text",
                      }}
                    >
                      {/* Welcome Back */}
                      VDBC
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    alignItems="center"
                    style={{ marginTop: "-30px" }}
                  >
                    <Typography variant="h2">Welcome to</Typography>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "45px",

                        // background:
                        //   "linear-gradient(180deg, #00ACEB 0%, #00B0ED 10.18%, #1069C2 70.35%, #1069C2 100%)",
                        // WebkitBackgroundClip: "text",
                        // WebkitTextFillColor: "transparent",
                        // backgroundClip: "text",
                      }}
                    >
                      {/* Welcome Back */}
                      VDBC
                    </Typography>
                  </Box>
                </>
              )}
              {/* <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              <img
                src="/images/logo.png"
                style={{ width: "350px", margin: "20px 0" }}
                alt=""
                onClick={() => history.push("/")}
              />
            </Box> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box className={classes.content}>{children}</Box>
        </Grid>
      </Grid>
      <Box className="themeButton">
        <IconButton
          variant="contained"
          color="primary"
          style={
            themeChange === "LIGHT"
              ? { backgroundColor: "#3da0e43b" }
              : { backgroundColor: "transparent" }
          }
          onClick={() => {
            changeTheme("LIGHT");
            seThemeChange("LIGHT");
            history.push({
              // search: "Light",
            });
          }}
        >
          <FiSun />
        </IconButton>
        <IconButton
          variant="contained"
          color="primary"
          style={
            themeChange === "DARK"
              ? { backgroundColor: "#3da0e43b" }
              : { backgroundColor: "transparent" }
          }
          onClick={() => {
            changeTheme("DARK");
            seThemeChange("DARK");
            history.push({
              // search: "Dark",
            });
          }}
        >
          <FaRegMoon />
        </IconButton>
      </Box>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText="Accept All Cookies"
        declineButtonText="Reject All"
        cookieName="myAwesomeCookieName2"
        style={{ background: "rgb(9 135 212)", zIndex: "9999" }}
        declineButtonStyle={{
          background: "#F5F5F5",
          borderRadius: "10px",
          color: "#000",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
          border: "1px solid #1069C2"
        }}
        buttonStyle={{
          background:
            "rgb(16 105 194)", borderRadius: "10px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "10px",
          color: "#fff",
          fontSize: "15px",
          lineHeight: " 20px",
          height: "40px",
          whiteSpace: "pre",
          minWidth: "100px",
        }}
        expires={150}
      >
        <Typography variant="body1" style={{ fontSize: "14px" }}>
          By clicking "Accept All Cookies", you agree to the store the cookies on your device for enhancing site navigation, analyzing site usage, and assisting in our marketing efforts.&nbsp;

        </Typography>
      </CookieConsent>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
