import {
  Typography,
  Box,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { BsArrowReturnRight, BsArrowReturnLeft } from "react-icons/bs";
import { GoUnverified } from "react-icons/go";
import { MdOutlineCallReceived } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { UserContext } from "src/context/User";
import { AuthContext } from "src/context/Auth";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import { toast } from "react-toastify";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import moment from "moment";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { SiGooglemessages } from "react-icons/si";
const useStyles = makeStyles((theme) => ({
  mainBox: {
    color: theme.palette.text.primary,
    "& subtitle2": {
      color: theme.palette.text.SideBar,
    },
    "& p": {
      color: theme.palette.text.SideBar,
    },
    "& .popup-p2p": {
      background:
        "linear-gradient(180deg, rgba(0, 172, 235, 0.1) 0%, rgba(0, 176, 237, 0.1) 10.18%, rgba(16, 105, 194, 0.1) 70.35%, rgba(16, 105, 194, 0.1) 100%)",
      marginRight: "12px",
    },
    "& .popup-default": {
      background:
        "linear-gradient(180deg, rgba(0, 172, 235, 0.1) 0%, rgba(0, 176, 237, 0.1) 10.18%, rgba(16, 105, 194, 0.1) 70.35%, rgba(16, 105, 194, 0.1) 100%)",
      marginRight: "12px",
      color: "#3a96dd",
    },
    "& .non-popup-p2p": {
      background:
        "linear-gradient(180deg, rgba(0, 172, 235, 0.1) 0%, rgba(0, 176, 237, 0.1) 10.18%, rgba(16, 105, 194, 0.1) 70.35%, rgba(16, 105, 194, 0.1) 100%)",
      marginRight: "12px",
    },
    "& .non-popup-default": {
      background:
        "linear-gradient(180deg, rgba(0, 172, 235, 0.1) 0%, rgba(0, 176, 237, 0.1) 10.18%, rgba(16, 105, 194, 0.1) 70.35%, rgba(16, 105, 194, 0.1) 100%)",
      marginRight: "12px",
      color: "#fff",
    },
  },
}));
export default function NotificationsList({ data, popUp }) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const notificationsLinks = window.location.pathname;
  const user = useContext(UserContext);

  const auth = useContext(AuthContext);

  const confirmationHandler = () => {
    setOpen(false);
  };

  const DeleteNotifiction = async () => {
    setIsLoading(true);
    // setsetisSubmit(true);

    try {
      const res = await Axios({
        method: "DELETE",
        url: ApiConfig.deleteNotificationList,
        headers: {
          token: window.localStorage.getItem("token"),
        },
        params: {
          _id: data?._id,
        },
      });
      if (res.data.responseCode === 200) {
        toast.success(res.data.responseMessage);
        user.NotificationDataList();

        setIsLoading(false);
      } else if (res.data.responseCode === 405) {
        toast.warn(res.data.responseMessage);
      } else {
        toast.warn(res.data.responseMessage);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      className={`${classes.mainBox} notication-list`}
      style={
        popUp
          ? {
            borderBottom: "1px solid #E9E9E9",
            padding: "0px 5px 7px",
            position: "relative",
            color: "#000",
          }
          : {
            // borderBottom: "1px solid #ccc",
            padding: "0px 10px 0px",
            position: "relative",
            color: "#000",
            borderBottom: "1px solid #aca7a7",
          }
      }
    >
      {open && (
        <ConfirmationDialog
          open={open}
          handleClose={() => setOpen(false)}
          title={"title"}
          desc={"desc"}
          confirmationHandler={confirmationHandler}
        />
      )}
      <Box display="flex">
        <Box>
          <IconButton
            className={
              popUp
                ? data?.notificationType === "P2P Advertisement"
                  ? "popup-p2p"
                  : "popup-default"
                : data?.notificationType === "P2P Advertisement"
                  ? "non-popup-p2p"
                  : "non-popup-default"
            }
          >
            {data?.notificationType === "P2P Advertisement" && (
              <>
                <LocalMallIcon />
              </>
            )}
            {data?.transactionType === "TRANSFER" && (
              <>
                <BsArrowReturnLeft />
              </>
            )}
            {data?.transactionType === "RECEIVEMONEY" && (
              <>
                <BsArrowReturnLeft />
              </>
            )}
            {data?.transactionType === "DEPOSIT" && (
              <>
                <BsArrowReturnRight />
              </>
            )}
            {data?.notificationType === "MONEY_TRANSFER_DEPOSITE" && (
              <>
                <BsArrowReturnRight />
              </>
            )}

            {data?.notificationType === "STACK_REPLY" && (
              <>
                {" "}
                <MdOutlineCallReceived />
              </>
            )}
            {data?.notificationType === "KYC_STATUS" && (
              <>
                {" "}
                <GoUnverified style={{ color: "green" }} />
              </>
            )}
          </IconButton>
        </Box>
        <Link style={{ textDecoration: "none", width: "100%" }}>
          <Box style={{ width: "calc(100% - 50px)" }}>
            <Box className="rightPosition d-flex" style={{ marginTop: "6px" }}>
              <Typography
                variant="subtitle2"
                // style={popUp ? { color: "" } : { color: "" }}
                pt={2}
              >
                {data?.createdAt
                  ? moment(data.createdAt).format("MMMM Do YYYY, h:mm:ss a ")
                  : "0"}
              </Typography>

              {popUp ? (
                ""
              ) : (
                <Tooltip title="Delete">
                  <IconButton>
                    <RiDeleteBin6Fill
                      onClick={() => DeleteNotifiction()}
                      style={{ color: "#DD3C3C" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box className="width120">
              {popUp ? (
                <>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (data?.notificationType === "P2P Advertisement") {
                        history.push({
                          pathname: "/buy",
                          // state: data?.chatId?._id,
                          state: {
                            chatId: data?.chatId?._id,
                            userId: data?.userId?._id,
                            timeOut:
                              data?.p2pAdvertisementId?.paymentWindowTime,
                          },
                          search: data?.chatId?.receiverId,
                          hash: data?.p2pAdvertisementId?._id,
                        });
                      }
                    }}
                  >
                    <Typography
                      variant="body1"
                      pt={2}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "400px",
                        fontSize: "14px",
                      }}
                    >
                      {data?.notificationType === "MONEY_TRANSFER_DEPOSITE" && (
                        <>{data?.subject}</>
                      )}
                      {data?.notificationType === "STACK_REPLY" && (
                        <>{data?.subject}</>
                      )}
                      {data?.notificationType === "KYC_STATUS" && (
                        <>{data?.subject}</>
                      )}
                      {data?.title}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        textOverflow: "ellipsis",

                        maxWidth: "300px",
                      }}
                      pt={2}
                    >
                      {data?.notificationType === "MONEY_TRANSFER_DEPOSITE" && (
                        <>{data?.body}</>
                      )}
                      {data?.notificationType === "STACK_REPLY" && (
                        <>{data?.body}</>
                      )}
                      {data?.notificationType === "KYC_STATUS" && (
                        <>{data?.body}</>
                      )}

                      {data?.description}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (data?.notificationType === "P2P Advertisement") {
                        history.push({
                          pathname: "/buy",
                          state: {
                            chatId: data?.chatId?._id,
                            userId: data?.userId?._id,
                            timeOut:
                              data?.p2pAdvertisementId?.paymentWindowTime,
                          },
                          search: data?.chatId?.receiverId,
                          hash: data?.p2pAdvertisementId?._id,
                        });
                      }
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      // style={{ color: "#848484" }}

                      pt={2}
                    >
                      {data?.notificationType === "MONEY_TRANSFER_DEPOSITE" && (
                        <>{data?.subject}</>
                      )}
                      {data?.notificationType === "STACK_REPLY" && (
                        <>{data?.subject}</>
                      )}
                      {data?.notificationType === "KYC_STATUS" && (
                        <>{data?.subject}</>
                      )}
                      {data?.title}
                    </Typography>

                    <Typography
                      variant="subtitle2"
                      style={{
                        textOverflow: "ellipsis",
                      }}
                      pt={2}
                    >
                      {data?.notificationType === "MONEY_TRANSFER_DEPOSITE" && (
                        <>{data?.body}</>
                      )}
                      {data?.notificationType === "STACK_REPLY" && (
                        <>{data?.body}</>
                      )}
                      {data?.notificationType === "KYC_STATUS" && (
                        <>{data?.body}</>
                      )}
                      {data?.description}
                    </Typography>
                  </Box>
                  <br />
                </>
              )}
            </Box>
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
