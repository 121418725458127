import React from "react";
import { Button, makeStyles, Typography, Box } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  Titlemain: {
    color: theme.palette.text.nofiction,
  },
  hedingText: {
    color: theme.palette.text.SideBar,
    fontSize: "22px",
  },
  subText: {
    color: theme.palette.text.SideBar,
    margin: "7px 0 14px",
  },
  logoutBox: {
    "& h6": {
      color: theme.palette.text.SideBar,
    },
    "& p": {
      color: theme.palette.text.SideBar,
    },
  },
  buttoncontrol: {
    width: "100%",
    maxWidth: "297px",
    margin: "0 auto",
    paddingTop: "24px",
  },
}));
export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.logoutBox}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box align="center" mt={1} mb={1}>
            <img
              src="images/logout.png"
              alt="images"
              style={{ width: "100%", maxWidth: "57px" }}
            />
          </Box>
          <Typography
            variant="h6"
            align="center"
            className={classes.hedingText}
          >
            {title}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            className={classes.subText}
          >
            {desc}
          </Typography>
          <Box
            pb={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.buttoncontrol}
          >
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => {
                handleClose();
                confirmationHandler();
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={handleClose}
              autoFocus
              style={{ marginLeft: "5px", fontSize: "14px" }}
            >
              No
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
